<template>
  <div>
    <CRow>
      <CCol sm="5">
        <CCard>
          <CCardHeader>
            Canjear licencias
          </CCardHeader>
          <CCardBody>
            <form @submit.prevent="submit">
            <CInput 
                placeholder="Ingresa el serial"
                v-model="form.code"
                required
              >
              </CInput>
              <CInput 
                placeholder="Nombre"
                v-model="form.name"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="id-card"
                /></template>
              </CInput>
              <CInput
                type="email"
                placeholder="Correo"
                v-model="form.email"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="at"
                /></template>
              </CInput>
              <CInput
                type="phone"
                placeholder="Teléfono"
                v-model="form.phone"
              >
                <template #prepend-content
                  ><font-awesome-icon icon="phone"
                /></template>
              </CInput>
              <CInput
                placeholder="Dirección"
                v-model="form.address"
              >
                <template #prepend-content
                  ><font-awesome-icon icon="location-arrow"
                /></template>
              </CInput>
              <CButton
                @click="send()"
                color="primary"
                :disabled="!is_enabled"
              >
                Canjear
              </CButton>
            </form>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol sm="7">
        <CCard>
          <CCardHeader>
            Licencias canjeadas
          </CCardHeader>
          <CCardBody>
            <div class="alert alert-success" v-if="just_redeem">
              Felicitaciones, sigue los siguientes pasos para activar tu licencia multipremiada de ESET: <br>
                <ol class="text-left">
                    <li>Copia el código de licencia abajo en ésta página.</li>
                    <li>Descarga el instalador y sigue las instrucciones de instalación.</li>
                    <li>Cuando el instalador te solicite, ingresa el código de licencia que copiaste.</li>
                    <li>Ahora ya estás protegido, disfruta de la tecnología de forma segura.</li>
                </ol>
                <small>Ahora toda la información que necesitas está en tu cuenta, ya no necesitas guardar tu tarjeta.</small>
            </div>
            <div class="row mb-2">
              <div class="col">
                  <select v-model="month" class="form-control">
                    <option value="">Mes</option>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
              </div>

              <div class="col">
                  <select v-model="year" class="form-control">
                    <option value="">Año</option>
                    <option value="2025">2025</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                  </select>
              </div>

              <div class="col">
                <CButton
                  @click="loadLicenses()"
                  color="primary"
                >
                  Buscar
                </CButton>
              </div>
          </div>
            <table class="table">
              <tr>
                <th>Cliente</th>
                <th>Licencias</th>
              </tr>
              <tr v-for="(mask, index) in masks" :key="index" :class="{'table-warning': index == 0 && just_redeem}">
                <td>
                  <strong>{{mask.redeem.name}}</strong> <br>
                  {{mask.redeem.email}}<br>
                  {{mask.redeem.phone}}<br>
                  <small>{{mask.customer.pivot.updated_at}}</small>
                </td>
                <td>
                  <div v-for="(license, index) in mask.redeem.licenses" :key="index" class="mb-4">
                    <strong>{{license.type}}</strong> <br>
                    Copia tu licencia: <br>
                    <strong ref="text" style="cursor: pointer" v-c-tooltip="'Click para copiar'" @click="copyToClipBoard(license.serial)">{{license.serial}}</strong> 
                    <font-awesome-icon style="color: #6cb2eb" icon="info-circle" v-c-tooltip="'Ingresa este código para activar tu producto'" /> <br>
                    <a v-if="license.link" :href="license.link" class="btn btn-primary text-white">
                      Descarga tu solucion
                    </a>
                    <a class="btn btn-primary text-white ml-1" @click="imprimir(license.serial, license.type)">
                      Imprimir
                    </a>
                  </div>
                </td>
              </tr>
            </table>
            <CPagination
            :activePage.sync="page"
            :pages="lastPage"
            size="sm"
            align="center"
            @update:activePage="pageChange"
          />
          </CCardBody>
        </CCard>
      </CCol>

    </CRow>
      
    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import ProfileResource from "@/api/user";
const profileResource = new ProfileResource();

export default {
  name: 'Redeem',
  data(){
    return {
      toasts: [],
      form: {
        code: '',
        name: '',
        phone: '',
        address: '',
        email: '',
        seller_email: ''
      },
      masks: [],
      seller_email: '',
      mask_data: {},
      just_redeem: false,
      month: '',
      year: '',
      page: 1,
      perPage: 10,
      lastPage: 1,
      is_enabled: true,
    }
  },

  async mounted() {
     await this.loadClient();
     await this.loadLicenses();
  },

  methods: {
    copyToClipBoard(textToCopy){
      navigator.clipboard.writeText(textToCopy);
      this.toasts.push({
        title: "Confirmación",
        color: "success",
        message: "Licencia copiada",
      });   
    },
    async loadClient() {
      await profileResource
        .profile()
        .then((response) => {
          this.seller_email = response.data.email;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async loadLicenses() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_SOFTWARE_CENTER_API_URL}/api/masks?page=${this.page}&seller_email=${this.seller_email}&month=${this.month}&year=${this.year}`
        );

        this.masks = [];
        this.mask_data = response.data;
        this.lastPage = response.data.meta.last_page;

        for (const mask of response.data.data) {
          const licenseResponse = await axios.get(
            `${process.env.VUE_APP_SOFTWARE_CENTER_API_URL}/api/masks/licenses?mask_id=${mask.id}`
          );
          mask.redeem = licenseResponse.data;
          this.masks.push(mask);
        }
      } catch (error) {
        console.error("Error loading licenses:", error);
      }
    },
    async send() {
      this.is_enabled = false;
      let formData = new FormData();
      formData.append('code', this.form.code);
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      formData.append('phone', this.form.phone);
      formData.append('address', this.form.address);
      formData.append('seller_email', this.seller_email);
      await axios.post(process.env.VUE_APP_SOFTWARE_CENTER_API_URL + '/api/masks/redeem',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Accept':'application/json'
          }
        }
      ).then((data) => {
        this.form = {
          name: '',
          code: '',
          phone: '',
          address: '',
          email: ''
        }
        this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Canjeado con exito",
          });
        this.masks = [];
        this.loadLicenses(1);
        this.just_redeem = true;
        this.is_enabled = true;
      })
      .catch((error) => {
        this.is_enabled = true;
        var errors = error.response.data.errors;
        if (errors == undefined) {
          var message = error.response.data.message;
          var error = {
            title: "Error al canjear licencias",
            color: "danger",
            message: message,
          };
        } else {
          var error = {};
          Object.keys(errors).forEach((key) => {
            error = {
              title: "Error al canjear licencias",
              color: "danger",
              message: errors[key][0],
            };
          });
        }
        this.toasts.push(error);
      });

    },
    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      this.page = val;
      await this.loadLicenses();
    },
    async imprimir(serial,product) {
      window.open(process.env.VUE_APP_BACKEND_URL+'/api/print-card?serial=' + serial + '&product=' + product, '_blank');
    },
  }
}
</script>
